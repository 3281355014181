import React from 'react';
import { connect } from 'react-redux';
import { withStyles, withTheme } from '@material-ui/core/styles';
import MasterDataUtilities from '../data/MasterDataUtilities';
import { DrcMain, DrcButton, DrcDialog, DrcTooltip, DrcDataGrid } from '@driscollsinc/driscolls-react-components';
import { setUploadedUserFiles, withdrawFile, setGridEditable, clearUserFileData } from '../actions/UserActions';
import { Middleware } from '@driscollsinc/one-ring';
import { DuDateUtilities, DuAuthenticationUtilities } from '@driscollsinc/driscolls-react-utilities';
import { withOktaAuth } from '@okta/okta-react';
import berryTypes from '../data/berryTypes';
import cloneDeep from 'lodash/cloneDeep';
import startCase from 'lodash/startCase';
import { getUploadTypeByDataType } from '../data/json/forecastTypes';
import APIEndPoints from '../services/api';
import { notify } from '../actions/NotificationAction';
import STATUS from '../data/fileStatuses';
import { makeBerryTypeCell, makeBerryFilterOptions, berryFilterTemplate } from '../components/makeBerryTypeCell';
import CloudUpload from '@material-ui/icons/CloudUpload';
import Undo from '@material-ui/icons/Undo';
import CloudDownload from '@material-ui/icons/CloudDownload';
import Visibility from '@material-ui/icons/Visibility';
import { handleFileDownload } from '../data/downloadFile';
import { showLoadingScreenAction, hideLoadingScreenAction, setPageTitleAction } from '../actions/actions';
const pageTitle = 'My Files';

const styles = (theme) => ({
    noBottom: {
        borderBottom: 'none',
        marginTop: 0
    },
    gridStyles: {
        '& .p-datatable-scrollable-wrapper': {
            borderRadius: '4px',
            overflow: 'hidden',
            boxShadow: '8px 8px 6px -6px #777'
        },
        '& .p-inputtext.p-component.p-column-filter': {
            borderRadius: '4px'
        },
        '& .p-dropdown label.p-dropdown-label': {
            textOverflow: 'ellipsis',
            width: '88%'
        }
    }
});

class UploadedFiles extends React.Component {
    state = {
        fileToWithdraw: null,
        warningTitle: null,
        warningContent: null,
        showWarning: false,
        fileUploadTitle: 'Upload File'
    };

    columnTooltip = (text) => (
        <DrcTooltip tipText={text}>
            <span>{text}</span>
        </DrcTooltip>
    );

    // async await doesn't work for drcgrid
    onFileClick = (row) => {
        if (row.Status === STATUS.withdrawn || row.Status === STATUS.approved) {
            return this.props.notify('No Records To View', true);
        }

        if (row.Status === STATUS.notApproved) {
            this.props.setGridEditable(true);
        }

        let fileType = row.DataType.split(' ').join('');
        let berryType = berryTypes.getDisplayNameByType(row.BerryType);

        this.props.history.push(`/EditViewFile/${fileType}/${berryType}/${row.Id}`);
    };

    cleanData = (data) => data.filter((e) => e.BerryType && e.Datatype && e.Name);

    // TODO : remove this once middleware starts changing it to DataType
    addKeyDataType = (data) =>
        data.map((e) => {
            e.DataType = e.Datatype;
            return e;
        });

    cannotWithDraw = (row) =>
        row.Status.toLowerCase() !== STATUS.uploaded.toLowerCase() &&
        row.Status.toLowerCase() !== STATUS.readyForReview.toLowerCase() &&
        row.Status.toLowerCase() !== STATUS.reUploaded.toLowerCase() &&
        row.Status.toLowerCase() !== STATUS.reSubmitted.toLowerCase();

    cannotUpload = (row) =>
        row.Status.toLowerCase() !== STATUS.withdrawn.toLowerCase() && row.Status.toLowerCase() !== STATUS.notApproved.toLowerCase();

    cannotDownload = (row) =>
        row.Status.toLowerCase() === STATUS.withdrawn.toLowerCase() ||
        row.Status.toLowerCase() === STATUS.approved.toLowerCase() ||
        row.Status.toLowerCase() === STATUS.uploaded.toLowerCase();

    columns = [
        { key: 'Actions', name: this.columnTooltip('Actions'), width: 148 },
        { key: 'DataType', name: this.columnTooltip('Forecast Type'), filter: true, filterElement: 'select', filterProps: {} },
        { key: 'BerryTypeImages', name: this.columnTooltip('Berry Type'), filter: true, filterElement: 'berryType', filterProps: {} },
        { key: 'Status', name: this.columnTooltip('File Status'), filter: true, filterElement: 'select', filterProps: {} },
        { key: 'ReviewComment', name: this.columnTooltip('Review Comments'), filter: true },
        { key: 'ReviewedBy', name: this.columnTooltip('Reviewed By'), filter: true, filterElement: 'select', filterProps: {} },
        { key: 'CreatedDateTime', name: this.columnTooltip('Created On'), filter: true, filterElement: 'dateRange', filterProps: {} },
        { key: 'ModifiedDateTime', name: this.columnTooltip('Modified On'), filter: true, filterElement: 'dateRange', filterProps: {} }
    ];

    handleWithdraw = (row) => {
        if (!this.cannotWithDraw(row)) {
            this.setState({
                fileToWithdraw: row.Id,
                showWarning: true,
                warningTitle: 'Withdraw Upload',
                warningContent: 'Are you sure you want to withdraw the file'
            });
        }
    };

    handleDownload = async (row) => {
        let token = await this.props.authState?.accessToken;
        await handleFileDownload(row, token);
    };

    async componentDidMount() {
        this.props.showLoadingScreenAction();
        if (this.props.pageTitle !== pageTitle) {
            this.props.setPageTitle(pageTitle);
        }

        this.props.clearUserFileData();
        this.props.setGridEditable(false);

        try {
            let token = await this.props.authState?.accessToken;
            let data = await Middleware.Send('SFUserFiles', token, APIEndPoints.MY_FILES, 'GET');
            let results = data.FileMasterDetails || [];

            // remove al records where berrytype and DataType is null
            // middle ware is not changing Datatype to DataType, adding addKeyDataType
            let cleanedData = this.addKeyDataType(this.cleanData(results));

            this.props.setUploadedUserFiles(cleanedData);
            this.props.hideLoadingScreenAction();
        } catch (error) {
            this.props.notify('An error occurred while fetching Files', true);
            this.props.hideLoadingScreenAction();
        }
    }

    getActionsCell = (row) => {
        let berryType = berryTypes.getDisplayNameByType(row.BerryType);
        let dataType = row.DataType.split(' ').join('');

        return (
            <div>
                <DrcButton
                    size="small"
                    isSecondary
                    noStyle
                    style={{
                        minWidth: '25px',
                        maxWidth: '25px',
                        margin: '4px 8px 8px 0',
                        color: this.props.theme.palette.primary.main
                    }}
                    onClick={() => this.handleWithdraw(row)}
                    title="Withdraw Upload"
                    disabled={this.cannotWithDraw(row)}
                >
                    <Undo />
                </DrcButton>
                <DrcButton
                    size="small"
                    isSecondary
                    noStyle
                    style={{
                        minWidth: '25px',
                        maxWidth: '25px',
                        margin: '4px 8px 8px 0',
                        color: this.props.theme.palette.primary.main
                    }}
                    title="Re Upload"
                    disabled={this.cannotUpload(row)}
                    to={`/Upload/${berryType}/${dataType}/${row.Id}`}
                >
                    <CloudUpload />
                </DrcButton>
                <DrcButton
                    size="small"
                    isSecondary
                    noStyle
                    style={{
                        minWidth: '25px',
                        maxWidth: '25px',
                        margin: '4px 8px 8px 0',
                        color: this.props.theme.palette.primary.main
                    }}
                    disabled={this.cannotDownload(row)}
                    onClick={() => this.handleDownload(row)}
                    title="Download File"
                >
                    <CloudDownload />
                </DrcButton>
                <DrcButton
                    size="small"
                    isSecondary
                    noStyle
                    style={{
                        minWidth: '25px',
                        maxWidth: '25px',
                        margin: '4px 8px 8px 0',
                        color: this.props.theme.palette.primary.main
                    }}
                    disabled={this.cannotDownload(row)}
                    onClick={() => this.onFileClick(row)}
                    title="View File"
                >
                    <Visibility />
                </DrcButton>
            </div>
        );
    };

    abort = () => {
        this.setState({ fileToWithdraw: null, showWarning: false, warningTitle: '', warningContent: '' });
    };

    accept = async () => {
        try {
            let token = await this.props.authState?.accessToken;

            let payload = {
                FileNbr: this.state.fileToWithdraw,
                Status: STATUS.withdrawn,
                ModifiedBy: DuAuthenticationUtilities.GetUserId(token),
                ModifiedDateTime: new Date().toISOString(),
                ReviewComment: ''
            };

            await Middleware.Send('SFUserFiles', token, `${APIEndPoints.WITHDRAW_FILE}/${payload.FileNbr}`, 'POST', payload);

            let dataToUpdate = {
                Id: payload.FileNbr,
                Status: STATUS.withdrawn,
                ModifiedBy: payload.ModifiedBy,
                ModifiedDateTime: payload.ModifiedDateTime
            };

            this.props.withdrawFile(dataToUpdate);

            this.setState({ showWarning: false, warningTitle: '', warningContent: '' });
            this.props.notify('File Withdrawn Successfully', false);
        } catch (error) {
            console.log(error);
            this.setState({ showWarning: false, warningTitle: '', warningContent: '' });
            this.props.notify('An Error Occurred while Withdrawing the file', true);
        }
    };

    customBerryTypeFilter = (e, args) => {
        if (e.props.berrytype === args) {
            return true;
        }
        return false;
    };

    render() {
        let { isMasterDataInitialized, classes } = this.props;

        if (!MasterDataUtilities.Check(isMasterDataInitialized)) {
            return MasterDataUtilities.Redirect();
        }

        let uploadedFiles = cloneDeep(this.props.uploadedFiles).map((file, index) => {
            file.CreatedDateTime = file.CreatedDateTime ? DuDateUtilities.ToString(new Date(file.CreatedDateTime)) : 'N/A';
            file.ModifiedDateTime = file.ModifiedDateTime ? DuDateUtilities.ToString(new Date(file.ModifiedDateTime)) : 'N/A';
            file.DataType = startCase(getUploadTypeByDataType(file.DataType));
            file.BerryTypeImages = makeBerryTypeCell(file.BerryType, index);
            file.Actions = this.getActionsCell(file);
            return file;
        });

        //update filter options for berrytype
        if (this.props.uploadedFiles.length > 0) {
            this.columns = this.columns.map((e) => {
                if (e.key === 'BerryTypeImages') {
                    e.filterProps.options = makeBerryFilterOptions(uploadedFiles, 'BerryTypeImages');
                    e.filterProps.itemTemplate = berryFilterTemplate;
                    e.filterProps.customFilterFunction = this.customBerryTypeFilter;
                }
                return e;
            });
        }

        return (
            <DrcMain>
                <div className="row">
                    <div className="col-xs-12">
                        <DrcDataGrid
                            columns={this.columns}
                            rows={uploadedFiles}
                            height={Math.max(window.innerHeight - 150, 300)}
                            gridStyles={classes.gridStyles}
                        />
                    </div>
                </div>
                <DrcDialog
                    title={this.state.warningTitle}
                    open={this.state.showWarning}
                    fullActionWrapper={true}
                    buttons={
                        <div style={{ float: 'right', marginRight: -8 }}>
                            <DrcButton isSecondary onClick={() => this.abort()}>
                                Oops, No Thanks
                            </DrcButton>
                            <DrcButton isPrimary onClick={() => this.accept()}>
                                Confirm
                            </DrcButton>
                        </div>
                    }
                >
                    {this.state.warningContent}
                </DrcDialog>
            </DrcMain>
        );
    }
}

function mapStateToProps(state) {
    return {
        pageTitle: state.rootReducer.pageTitle,
        isMasterDataInitialized: state.masterReducer.isInitialized,
        uploadedFiles: state.userReducer.uploadedFiles
    };
}

const mapDispatchToProps = (dispatch) => ({
    setPageTitle: (title) => dispatch(setPageTitleAction(title)),
    setUploadedUserFiles: (userFiles) => dispatch(setUploadedUserFiles(userFiles)),
    withdrawFile: (data) => dispatch(withdrawFile(data)),
    setGridEditable: (val) => dispatch(setGridEditable(val)),
    notify: (data, isError) => dispatch(notify(data, isError)),
    clearUserFileData: () => dispatch(clearUserFileData()),
    showLoadingScreenAction: (data) => dispatch(showLoadingScreenAction(data)),
    hideLoadingScreenAction: (data) => dispatch(hideLoadingScreenAction(data))
});

export default withOktaAuth(connect(mapStateToProps, mapDispatchToProps)(withTheme(withStyles(styles)(UploadedFiles))));
